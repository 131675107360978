import { Link, NavLink } from 'react-router-dom'
import { useState } from 'react'
import './index.scss'
import LogoS from '../../assets/images/logo-s.png'
import LogoSubtitle from '../../assets/images/logo_sub.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBriefcase,
  faEnvelope,
  faHome,
  faTools,
  faUser,
  faBars,
  faClose,
} from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faGithub, faWordpress } from '@fortawesome/free-brands-svg-icons'

const Sidebar = () => {
  const [displayNav, setDisplayNav] = useState(false)

  return (
    <div className="navbar">
      <Link className="nav-logo" to="/" onClick={() => setDisplayNav(false)}>
        <img className="logos" src={LogoS} alt="logo" />
        <img className="sub-logo" src={LogoSubtitle} alt="scipio" />
      </Link>
      <nav className={displayNav ? 'nav-items' : ''}>
        <NavLink
          exact="true"
          activeclassname="active"
          to="/"
          onClick={() => setDisplayNav(false)}
        >
          <FontAwesomeIcon icon={faHome} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="about-link"
          to="/about"
          onClick={() => setDisplayNav(false)}
        >
          <FontAwesomeIcon icon={faUser} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="portfolio-link"
          to="/portfolio"
          onClick={() => setDisplayNav(false)}
        >
          <FontAwesomeIcon icon={faBriefcase} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="skills-link"
          to="/skills"
          onClick={() => setDisplayNav(false)}
        >
          <FontAwesomeIcon icon={faTools} color="#4d4d4e" />
        </NavLink>
        <NavLink
          activeclassname="active"
          className="contact-link"
          to="/contact"
          onClick={() => setDisplayNav(false)}
        >
          <FontAwesomeIcon icon={faEnvelope} color="#4d4d4e" />
        </NavLink>
        <FontAwesomeIcon
          onClick={() => setDisplayNav(false)}
          icon={faClose}
          color="#534717"
          size="3x"
          className="close-icon"
        />
      </nav>
      <ul>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/in/scipio-mundine-jr"
          >
            <FontAwesomeIcon icon={faLinkedin} color="#4d4d4e" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/fitdevotaku"
          >
            <FontAwesomeIcon icon={faGithub} color="#4d4d4e" />
          </a>
        </li>
        <li>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://creatorsphysique.com"
          >
            <FontAwesomeIcon icon={faWordpress} color="#4d4d4e" />
          </a>
        </li>
        <p className="para1">&copy; Scipio 2022</p>
      </ul>
      <FontAwesomeIcon
        onClick={() => setDisplayNav(true)}
        icon={faBars}
        size="3x"
        color="#534717"
        className="hamburger-icon"
      />
    </div>
  )
}
export default Sidebar
