import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import AnimatedLetters from '../AnimatedLetters'
// import Logo from './Logo'
import './index.scss'
import Loader from 'react-loaders'
import outterSpace from '../../Outterspace.mp4'

const Home = () => {
  const [letterClass, setLetterClass] = useState('text-animate')

  const nameArray = ['S', 'c', 'i', 'p', 'i', 'o', ' ,']

  const jobArray = [
    'J',
    'a',
    'v',
    'a',
    ' ',
    'd',
    'e',
    'v',
    'e',
    'l',
    'o',
    'p',
    'e',
    'r',
    '.',
  ]

  useEffect(() => {
    setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 4000)
  }, [])
  
  return (
    <>
      <div className="container home-page">
        <video autoPlay muted playsInline loop src={outterSpace}/>
        {/* Created a loop in final cut pro. Video contents credited to the creators at pexels.com copyright free images/videos */}
        <div className="text-zone">
          <h1>
            <AnimatedLetters
              letterClass={letterClass}
              strArray={nameArray}
              idx={15}
            />
            <br />
            <AnimatedLetters
              letterClass={letterClass}
              strArray={jobArray}
              idx={22}
            />
          </h1>
          <h2>Full Stack Engineer</h2>
          <Link to="/contact" className="flat-button">
            {' '}
            CONTACT ME
          </Link>
        </div>
        {/* <Logo /> */}
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Home
