import React from 'react'
import { useEffect, useState } from 'react'
import Loader from 'react-loaders'
import './index.scss'
import AnimatedLetters from '../AnimatedLetters'

const skills = [
  { href: '#html5_', title: 'HTML5' },
  { href: '#css3_', title: 'CSS3' },
  { href: '#tailwindcss', title: 'Tailwind Css' },
  { href: '#javascript_', title: 'JavaScript' },
  { href: '#typescript_', title: 'TypeScript' },
  { href: '#react_', title: 'React' },
  { href: '#wordpress_', title: 'WordPress' },
  { href: '#node_', title: 'Node' },
  { href: '#python_', title: 'Python' },
  { href: '#java', title: 'Java' },
  { href: '#springframework', title: 'Spring Framework' },
  { href: '#angular', title: 'Angular' },
  { href: '#express_', title: 'Express' },
  { href: '#bootstrap_', title: 'Bootstrap' },
  { href: '#mongoose_', title: 'Mongoose' },
  { href: '#mongodb_', title: 'MongoDB' },
  { href: '#mysql', title: 'MySQL' },
  { href: '#github_', title: 'Github' },
  { href: '#adobephotoshop_', title: 'Photoshop' },
  { href: '#aws', title: 'AWS' },
  { href: '#figma_', title: 'Figma' },
  { href: '#webrtc', title: 'WebRTC' },
]

const Skills = ({ ...rest }) => {
  useEffect(() => {
    console.log('Loading TagCanvas...')
    const TagCanvas = window.TagCanvas
    const tagCanvasOptions = {
      textColour: '#fff',
      outlineThickness: 0.5,
      outlineColour: '#FE0853',
      maxSpeed: 0.06,
      freezeActive: true,
      shuffleTags: true,
      shape: 'sphere',
      zoom: 0.8,
      wheelZoom: false,
      noSelect: true,
      textFont: null,
      freezeDecel: true,
      fadeIn: 3000,
      initial: [0.3, 0],
      depth: 1.1,
    }
    try {
      TagCanvas.Start('tagcanvas', 'taglist', tagCanvasOptions)
    } catch (e) {
      console.log('Canvas error.')
      console.log(e)
    }
  }, [])

  const [letterClass, setLetterClass] = useState('text-animate')
  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass('text-animate-hover')
    }, 3000)

    return () => {
      clearTimeout(timer)
    }
  })

  return (
    <>
      <div className="container skills-page">
        <div className="text-zone">
          <h1 className="page-title">
            <AnimatedLetters
              letterClass={letterClass}
              strArray={[
                'S',
                'k',
                'i',
                'l',
                'l',
                ' s',
                ' ',
                '&',
                ' ',
                'E',
                'x',
                'p',
                'e',
                'r',
                'i',
                'e',
                'n',
                'c',
                'e',
              ]}
              idx={15}
            />
          </h1>
          <p>
            I am well versed in frontend and backend technologies such as
            MERN stack, Java, Spring boot and Angular.
          </p>
          <p>
            I am versatile in learning new technologies and have a great sense
            to aesthetically pleasing designs. I specialize in object-oriented programming and utilizing microservices in Spring Framework,
            focusing on system design and backend development for cloud-based applications, particularly with platforms like AWS.
          </p>
        </div>
        <div className="clear"></div>

        <div className="canvas-container">
          <canvas
            id="tagcanvas"
            width="820"
            height="600"
            style={{
              maxWidth: '1000px',
              width: '100%',
              zIndex: '99',
              position: 'relative',
              margin: '0 auto',
            }}
            className="to-fade-in fast-anim"
          ></canvas>
          <div id="taglist" style={{ display: 'none' }}>
            <ul className="text-spinner">
              {skills.map((skill) => (
                <li key={skill.title}>
                  <a href={skill.href}>{skill.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Loader type="pacman" />
    </>
  )
}

export default Skills
